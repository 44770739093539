<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title></b-card-title>
      <b-card-text class="mr-25 mb-0">
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in _statisticsItems"
          :key="item.icon"
          md="3"
          sm="6"
          class="mb-2 mb-md-4"
          :class="item.customClass"
        >
          <br />
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  computed: {
    ...mapState('dashboardDataStore', ['results', 'loading']),
    ...mapGetters('dashboardDataStore', ['_partnerInfo', '_isAdmin']),
    _statisticsItems() {
      const { countImage, countPurchaseCreditFail, purchaseCredit, purchaseImage } = this.results.clientDashboardOne

      return [
        {
          icon: 'TrendingUpIcon',
          color: 'light-success',
          title: this.withCommas(purchaseCredit),
          subtitle: 'ยอดเติมรวม',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ImageIcon',
          color: 'light-success',
          title: this.withCommas(purchaseImage),
          subtitle: 'ยอดซื้อรอม',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ChromeIcon',
          color: 'light-success',
          title: this.withCommas(countImage, false),
          subtitle: 'จำนวนรูปที่ซื้อ',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'AlertTriangleIcon',
          color: 'light-danger',
          title: this.withCommas(countPurchaseCreditFail, false),
          subtitle: 'จำนวนเติ่มไม่สำเร็จ',
          customClass: '',
        },
      ]
    },
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-success',
          title: '230k',
          subtitle: 'ยอดขาย',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ImageIcon',
          color: 'light-success',
          title: '8.549k',
          subtitle: 'จำนวน รูป',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ChromeIcon',
          color: 'light-success',
          title: '1.423k',
          subtitle: 'จำนวน ราศี',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'AlertTriangleIcon',
          color: 'light-danger',
          title: '$9745',
          subtitle: 'รูปรอการตรวจสอบ',
          customClass: '',
        },
      ],
    }
  },
}
</script>
